import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SVGSeparator from '../components/project-blocks/separator'
import InformationSectionBlock from '../components/project-blocks/information-section'

import { informationSectionData } from '../data/project-data--gattaca'

const HeaderVideo = styled.div`
	width: 100%;
	height: auto;

	padding: 56.25% 0 0 0;
	position: relative;
	margin-bottom: 12px;

	& > iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const Gattaca = () => (
	<Layout>
		<SEO title="GATTACA Opening Titles Reimagined" />
		<HeaderVideo>
			<iframe
				title="GATTACA Opening Titles Video Player"
				src="https://player.vimeo.com/video/260152307?title=0&byline=0&portrait=0"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			/>
		</HeaderVideo>
		<HeadingGroup>
			<Title>GATTACA</Title>
			<Subtitle>Opening Titles Reimagined</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<InformationSectionBlock data={informationSectionData} />
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

export default Gattaca
